<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-tab
          type="tabcard"
          :tabItems.sync="tabItems"
          :inlineLabel="true"
          v-model="tab"
          align="left"
        >
          <template v-slot:default="tab">
            <component
              :is="tab.component"
              :data="data"
              :dateItems="dateItems"
              :equipmentName="popupParam.equipmentName"
              :equipmentCd="popupParam.equipmentCd"
              :grid.sync="grid"
              :grid2.sync="grid2"
              @getDetail="getDetail"
              @setToday="setToday"
            />
          </template>
        </c-tab>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import mobileConfig from '@/js/mobileConfig';
export default {
  name: 'equipment-daily-checking',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        dailyCheckId: '',
        equipmentCd: '',
        lineCd: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      year: '',
      grid: {
        columns: [],
        data: [],
      },
      grid2: {
        columns: [],
        data: [],
      },
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        dailyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        lineId: '',  // 라인일련번호
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        dailyName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        utilityFlag: 'N',
        dailyResultModelList: [], // sheet
        mobileImproveModelList: [], 
      },
      updateMode: false,
      editable: true,
      saveUrl: transactionConfig.sop.min.equipment.plan.daily.insert.url,
      insertUrl: '',
      updateUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      currentMonth: '',
      lunarHolidays: [],
      dateItems: [],
      today: null,
      checkboxItems: [
        // { code: null, codeName: ''},
        { code: 'CHK001', codeName: 'o'},
        { code: 'CHK002', codeName: 'X'},
      ],
      tab: 'equipmentDailyResult',
      tabItems: [
        { name: 'equipmentDailyResult', icon: 'list', label:  '점검결과', component: () => import(`${'./equipmentDailyResult.vue'}`) },
        { name: 'equipmentDailyImpr', icon: 'health_and_safety', label: '개선 및 정비요청', component: () => import(`${'./preventiveCheckImpr.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
     return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 170);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
      this.grid2.height = height + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);
      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      if (String(this.today.charAt(0)) === '0') {
        this.today = this.today.substring(1)
      }
      // url setting
      this.getUrl = mobileConfig.sop.min.equipment.plan.daily.get.mobile.url;
      this.insertUrl = transactionConfig.sop.min.equipment.plan.daily.insert.url;
      this.updateUrl = transactionConfig.sop.min.equipment.result.daily.newItem.url;
      // code setting
      // list setting
      this.search()
    },
    search() {
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.dailyCheckId) {
        this.$http.url = this.getUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          dailyCheckId: this.popupParam.dailyCheckId,
          equipmentCd: this.popupParam.equipmentCd,
          colKey: 'day' + this.today,
        }
        this.$http.request((_result) => {
          this.data = _result.data;

          this.grid.data = this.$_.filter(_result.data.dailyResultModelList)

          if (!this.data.mobileImproveModelList) {
            this.data.mobileImproveModelList = [];
          }
          if (this.grid.data) {
            this.$_.forEach(this.grid.data, _item => {
              if(_item['day' + this.today] && 
                  (this.$_.findIndex(this.checkboxItems, {code: _item['day' + this.today]}) === -1)) {
                _item.remark = _item['day' + this.today]
              }
            })
          }
          if (this.grid2.data) {
            this.$_.forEach(this.grid2.data, _item => {
              if(_item['day' + this.today] && 
                  (this.$_.findIndex(this.checkboxItems, {code: _item['day' + this.today]}) === -1)) {
                _item.remark = _item['day' + this.today]
              }
            })
          }
          this.setColumns();
        },);
      }
    },
    setToday(data) {
      this.today = data;
      this.getDetail();
    },
    setColumns() {
      let _columns = [
        // {
        //   name: 'checkItemPartName',
        //   field: 'checkItemPartName',
        //   label: '부품명',
        //   align: 'center',
        //   style: 'width:120px',
        //   sortable: false,
        //   fix: true,
        // },
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '점검항목',
          align: 'center',
          style: 'width:120px',
          sortable: false,
          fix: true,
        },
      ]

      let days = [];
      let year = this.data.checkDate.substring(0, 4);
      let month = this.data.checkDate.substring(5);

      let date = new Date(Number(year), Number(month), 0);

      for (let i=1; i<=date.getDate(); i++) {
        this.dateItems.push({
          dayName: i + '일',
          dayCode: String(i),
        })
      }

      if (this.data.checkDate) { 
        days.push(
          {
            name: 'day' + this.today,
            field: 'day' + this.today,
            label: '선택',
            currentDay: String(this.today) === String(this.today) && String(month) === String(this.currentMonth) 
                      ? true : false, 
            align: 'center',
            size: 'xs',
            setHeader: true,
            style: 'width:80px',
            comboItems: this.checkboxItems,
            type: 'select',
            sortable: false,
          },
          {
            name: 'gaugeWarn',
            field: 'gaugeWarn',
            label: '경고값',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'gaugeExec',
            field: 'gaugeExec',
            label: '실행값',
            align: 'center',
            style: 'width:80px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'day' + this.today + 'C',
            field: 'day' + this.today + 'C',
            label: '값',
            align: 'center',
            type: 'custom',
            style: 'width:60px',
            sortable: false,
          },
        )
        this.grid.columns = this.$_.concat(_columns, {
          name: 'today',
          field: 'today',
          label: this.today + '일',
          child: days
        })
        this.grid.columns.push(
          // {
          //   name: 'recentDay',
          //   field: 'recentDay',
          //   label: '이달최근점검',
          //   align: 'center',
          //   style: 'width:90px',
          //   sortable: false,
          // },
          // {
          //   name: 'cbmAlert',
          //   field: 'cbmAlert',
          //   label: '수치값 상태',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: false,
          //   type: 'custom',
          // },
          {
            name: 'customDetail',
            field: 'customDetail',
            label: '',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false
          },
          // {
          //   name: 'checkItemCycleName',
          //   field: 'checkItemCycleName',
          //   label: '주기',
          //   align: 'center',
          //   style: 'width:50px',
          //   sortable: false,
          // },
        )
      }
      if (this.data.checkDate && this.data.lineId) {
        this.data.dailyName = year + '년도  ' + month + '월  ' + this.popupParam.lineCd
      }
      this.$_.forEach(this.grid.data, _item => {
        for (let i=1; i<this.today; i++) {
          if (_item['day'+i] && _item['day'+i] !== null) {
            _item.recentDay = i  + '일 [' + _item['day'+i+'Name']+ ']'
          }
        }
      })
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>
